<template>
	<div>
		<div class="head-area">
			<!-- <img :src="png_head" alt="" style=""> -->
			<div class="head-label">
        <div style="padding-top: 80px;">
					人力外包服务
				</div>
        <p style="font-size: 16px;padding-bottom: 50px;padding-top: 10px">利用组织外部的资源，更经济、更有效地解决组织内部人力资源活动所涉及的工作，降低经营成本，实现企业效益的最大化</p>
			</div>
			<div class="head-btn">
				 <div @click="addneed()" style="background-color: #0db88a;padding: 10px 30px;border-radius: 2px;cursor:pointer;">
					发布需求
				 </div>
				 <!-- <button style="background-color: #0db88a;padding: 5px 20px;border-radius: 2px;">fabu</button> -->
			</div>
		</div>
		<div style="width: 1440px;margin: 0 auto;background-color: white">
			<img :src="png_human1" alt="" style="">
			<img :src="png_human2" alt="" style="">
			<img :src="png_human3" alt="" style="">


      <div style="width: 100%;background-color: #F5F7FA;padding: 60px 0">
        <div class="title" style="margin-top: 0">案例展示</div>
        <div class="intro"><span style="color: #0db88a;font-size: 16px;font-weight: 500;">1000+</span>合作企业，精选实战案例一览</div>
        <div class="company-list">
          <div class="logo-list" >
            <img :src="baseURL + item" v-for="item in this.case" style="width: 270px;height: 360px;margin-bottom: 40px">
          </div>
        </div>
      </div>
<!--			<img :src="png_human4" alt="" style="">-->

      <div style="width: 1440px;padding-bottom: 40px">
        <div class="title">我们的客户</div>
        <div class="intro">我们已经为百余家企业提供了职业素养、技能管理、人力资源等方面的优质培训，遍布金融、地产、汽车等行业</div>
        <div class="company-list">
          <div class="logo-list" >
            <img :src="baseURL + item" v-for="item in customer" style="width: 228px;height: 80px;margin-bottom: 20px">
          </div>
        </div>
      </div>
<!--			<img :src="png_labor3" alt="" style="">-->
		</div>
	</div>
	
</template>

<script>
	import png_head from '@/assets/images/company/companyHead.jpg'
	import png_human1 from '@/assets/images/company/human1.png'
	import png_human2 from '@/assets/images/company/human2.png'
	import png_human3 from '@/assets/images/company/human3.png'
	import png_human4 from '@/assets/images/company/human4.png'
	import png_labor3 from '@/assets/images/company/labor3.png'
  import logo from '@/assets/images/company/Train_logo1.png'
  import {HumanOutsource_url, baseURL} from '../../utils/request/apis'
export default {
  name: "HumanOutsource",
  data() {
  	return {
      logo,
  		png_head,
		png_human1,
		png_human2,
		png_human3,
		png_human4,
		png_labor3,
      case:[],
      customer:[],
      baseURL :'',
  	}
  },
  created() {
    this.baseURL = baseURL;
    this.getListData()
  },
  methods:{
	  addneed() {
      const url = this.$router.resolve({ name: 'outsourcingofmanpower' })
      window.open(url.href)
	  },
    getListData(){
      var caseTemp = '';
      var customerTemp = '';
      this.$http.get(HumanOutsource_url).then(res => {
        if(res.success){
          console.log("1111",res);
          const temp = res.result.records;
          for(var i = 0 ; i < temp.length ; i++){
            if(temp[i].type == 1){
              caseTemp += temp[i].image;
              caseTemp += ','
            }
            if(temp[i].type == 2){
              customerTemp += temp[i].image
              customerTemp += ','
            }
          }
          if(caseTemp.endsWith(',')){
            caseTemp = caseTemp.substring(0,caseTemp.length-1);
          }
          if(customerTemp.endsWith(',')){
            customerTemp = customerTemp.substring(0,customerTemp.length-1);
          }
          this.case = caseTemp.split(',');
          this.customer = customerTemp.split(',');
          console.log("case",this.case);
          console.log("customer",this.customer);
        } else {
          console.log("222222")
          this.$message(res.message);
        }
      })
    },
  }
}
</script>

<style scoped>
	.head-label {
		/*display:flex;*/
		text-align:center;
		align-items:center;
		justify-content: center;
		color: #fff;
		/*line-height:240px;*/
		font-size:30px;
	}
	.head-btn {
		display:flex;
		text-align:center;
		align-items:center;
		justify-content: center;
		color: #fff;
		/* background-color:#0db88a; */
		/* width: fit-content; */
		font-size:13px;
		margin-top: 0px;
	}
	.head-area {
		/* width: 380px; */
		/* position: absolute;
		right: 16%;
		top: 16%; */
		width: 100%;
		height:300px;
		background: white;
		padding: 0px;
		/* border-radius: 12px; */
		text-align: left;
		background-image: url('../../assets/images/company/companyHead.jpg');
		background-size: 100% 100%;
	}
  .title{
    margin-top: 60px;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    color: #303133;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }
  .intro{
    margin-top: 8px;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #606266;
  }
  .company-list{
    width: 1200px;
    margin: 0 auto;
    margin-top: 32px
  }
  .logo-list{
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }

</style>
